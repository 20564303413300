<template>
  <div class="loginmain">
    <tip></tip>
    <div class="loginheader">
      <img src="@/assets/img1/LOGONEW.png" alt="" />
    </div>
    <div class="logincontent">
      <div>
        <div class="boss">
          <!-- <div class="reserved">
          Copyright © 2022-2023 KnownTech. All Rights Reserved. 知也云科技 版权所有 备案号：<i @click="linkto">鄂ICP备18021828号-7</i>
        </div> -->
          <div
            class="codeLogin"
            @click="changType('code')"
            v-show="loginType == 'phone'"
          >
            <img src="@/assets/img1/icon/logincode.png" alt="" />
          </div>
          <div
            class="codeLogin"
            @click="changType('phone')"
            v-show="loginType != 'phone'"
          >
            <img src="@/assets/img1/icon/phonelogin.png" alt="" />
          </div>
          <div class="boss-left">
            <div class="text_info">
              <div class="title_l1">标书查重专家！</div>
              <div class="title_l2">
                适用于jun队、政府、医疗、高校、国企等招标项目投标前自查！
              </div>
              <div class="title_l2">jun队标书查重供应商目录入驻品牌！</div>
            </div>
            <img src="@/assets/img1/login-left.png" alt="" />
          </div>
          <div class="loginbox">
            <div class="loginwrap" v-show="loginType == 'phone'">
              <p class="login-title">手机号登陆</p>
              <div class="inputbox">
                <div class="inputleft username">
                  <img src="@/assets/img/checkimg/编组.png" alt="" />
                </div>
                <input
                  type="text"
                  class="inputtext"
                  placeholder="请输入手机号"
                  v-model="loginForm.username"
                />
              </div>
              <div class="inputbox userpwd">
                <div class="inputleft">
                  <img src="@/assets/img/checkimg/编组2.png" alt="" />
                </div>
                <input
                  type="password"
                  class="inputtext"
                  @keyup.enter="handleLogin"
                  placeholder="请输入密码"
                  v-model="loginForm.password"
                />
              </div>
              <div class="reset-password" @click="handleResetPassword">
                忘记密码
              </div>
              <div class="tologin" @click="handleLogin">立即登陆</div>
              <div class="toregist">
                没有账号，<span @click="handleRegister">马上注册</span>
              </div>
              <div class="toregist" @click="linkHelp" style="font-size: 12px">
                登陆异常，请参考
                <span style="font-size: 12px">登陆异常帮助文档</span>
              </div>
            </div>
            <div class="loginwrap" v-show="loginType != 'phone'">
              <p class="login-title">微信登陆</p>
              <!-- <div id="wxcode" class="codeBox">
            </div> -->
              <div class="codeBox">
                <img :src="qrCode" style="width: 100%" />
              </div>
              <div class="toregist">
                没有账号，<span @click="handleRegister">马上注册</span>
              </div>
              <div class="toregist" @click="linkHelp" style="font-size: 12px">
                登陆异常，请参考
                <span style="font-size: 12px">登陆异常帮助文档</span>
              </div>
            </div>
            <!-- <div class="codeNumber">
            <span style="cursor:pointer" @click="linkto">鄂ICP备2023001230号-2</span>
          </div> -->
          </div>
        </div>
        <div class="reserveds">
          Copyright © 2023-2033 Prosuntech. All Rights Reserved. 彦旭科技
          版权所有 备案号：<i @click="linkto" style="font-size: 12px"
            >鄂ICP备18021828号-7</i
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/utils/wxLogin.js";
import {
  userLogin,
  getwxImg,
  getQrToken,
  getLoginQrCode,
  wechatOpenIdLogin,
  openwxLogin,
} from "@/api/saasuser.js";
import { settoken } from "@/utils";
import tip from "@/component/tip.vue";

export default {
  components: {
    tip,
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        app: "CHECK",
      },
      loginType: "phone",
      url: "",
      qrCode: "",
      getToken: "",
      timer: null,
      openId: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer); // 销毁定时器
    next();
  },
  mounted() {
    // this.getimg()
  },
  methods: {
    linkto() {
      window.open("https://beian.miit.gov.cn/");
    },
    async showImg() {
      // let state = new Date().getTime()
      // const oScript = document.createElement('script');
      // oScript.type = 'text/javascript';
      // oScript.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
      // document.body.appendChild(oScript);

      // 二维码扫描登录
      // this.$nextTick(() => {
      //   var obj = new WxLogin({
      //     self_redirect: false,
      //     id: "wxcode",//wx组建元素
      //     appid: 'wx2d7a1249565d1711',//微信平台开放id
      //     scope: "snsapi_login",
      //     redirect_uri: 'https%3A%2F%2Fapi.kicheck.cc%2Fbid-check-service%2Fopen%2Fwx%2Fcallback',//回调地址 encodeURIComponent编码
      //     state: '11111',
      //     style: "black",//黑白样式
      //     href: "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5xcmNvZGUubGlnaHRCb3JkZXIgewogIHdpZHRoOiAxNTVweDsKICBoZWlnaHQ6IDE1NXB4OwogIG1hcmdpbi10b3A6IDA7CiAgYm94LXNpemluZzogYm9yZGVyLWJveDsKfQouaW1wb3dlckJveCAuaW5mbyB7CiAgZGlzcGxheTogbm9uZTsKfQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7CiAgd2lkdGg6IDE1NXB4Owp9Cg=="//通过href base64加密css可以微调样式
      //   });
      // })
      let { data } = await getQrToken();
      if (data.code == 200) {
        this.getToken = data.data;
        let codeUrl = await getLoginQrCode(this.getToken);
        if (codeUrl.data.code == 200) {
          this.qrCode = codeUrl.data.data;
          this.timer = setInterval(async () => {
            let res = await wechatOpenIdLogin({ token: this.getToken });
            if (res.data.code == 200) {
              clearInterval(this.timer);
              this.openId = res.data.data;
              window.open(
                `https://api.kicheck.cc/authority-center/open/wx/Login?openid=${this.openId}`,
                "_self"
              );
            }
          }, 3000);
        }
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    linkHelp() {
      // window.open(
      //   "https://docs.kicheck.cc/saas-ban/chang-jian-wen-ti-jie-da/deng-lu-ye-mian-yi-chang-zen-me-ban"
      // );
      window.open("https://docs.kicheck.cc/saas-ban/zhu-ce-deng-lu/deng-lu");
    },
    async getimg() {
      let { data } = await getwxImg("155415412554");
      console.log(data);
      this.url = data.split("redirect:")[1];
    },
    changType(type) {
      if (type == "code") {
        this.showImg();
        this.loginType = type;
      } else {
        this.$router.go(0);
      }
    },
    handleRegister() {
      this.$router.push("/register");
    },
    handleResetPassword() {
      this.$router.push("/resetPassword");
    },
    async handleLogin() {
      var reg =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|(147))\\d{8}$";
      var regExp = new RegExp(reg);
      if (!regExp.test(this.loginForm.username)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return 0;
      }
      let { data } = await userLogin(this.loginForm);
      if (data.code == 200) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
        });
        // window.sessionStorage.setItem('username', this.loginForm.username)
        settoken(data.data);
        this.$router.push({ path: "/index" });
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loginmain {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100vh;
  // background: url("@/assets/img1/loginbackground.png") no-repeat;
  // background-size: 100% 100%;

  .loginheader {
    width: 100%;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: left;

    img {
      height: 32px;
      margin-left: 16px;
    }
  }

  .logincontent {
    height: calc(100vh - 56px);
    background: url("@/assets/img1/loginbackground.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .reserved {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 31px;
      left: 0;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #74798c;
      line-height: 17px;

      i {
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
}

.boss {
  width: 961px;
  height: 480px;
  box-shadow: 0px 4px 30px 0px rgba(196, 196, 196, 0.5);
  border-radius: 12px;
  background: #ffffff;
  // overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;

  .codeLogin {
    position: absolute;
    right: 7px;
    top: 7px;

    img {
      width: 38px;
      height: 39px;
      cursor: pointer;
    }
  }

  .boss-left {
    width: 50%;
    flex: 1;
    position: relative;

    img {
      // width: 490px;
      // height: 480px;
      height: 100%;
    }

    .text_info {
      position: absolute;
      left: 30px;
      top: 45px;

      .title_l1 {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fff;
        line-height: 25px;
        user-select: none;
      }

      .title_l2 {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
        margin-top: 16px;
        user-select: none;
      }
    }
  }
}
.reserveds {
  margin-top: 20px;
  text-align: center;
  color: #74798c;
  font-size: 12px;
}

.loginwrap {
  width: 316px;
  border-radius: 8px;
  padding-bottom: 25px;
  overflow: hidden;
  margin: 74px 61px 0 0;

  .codeBox {
    width: 189px;
    height: 189px;
    margin: 0 auto;
    margin-bottom: 24.5px;
    margin-top: 9px;
  }
}

.login-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #006efe;
  line-height: 28px;
  text-align: center;
  margin: 0 0 33px 0;
}

.inputleft img {
  width: 19px;
  height: 21px;
}

.inputleft {
  width: 41px;
  height: 48px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputtext {
  width: 275px;
  height: 48px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 2, 2, 0.4);
  line-height: 22px;
  outline: none;
}

.inputbox {
  display: flex;
  justify-content: left;
}

.userpwd {
  margin-top: 5px;
}
.reset-password {
  text-align: right;
  font-size: 12px;
  margin-top: 15px;
  color: #006efe;
  cursor: pointer;
}

.tologin {
  width: 314px;
  height: 45px;
  background: #006efe;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  margin-top: 15px;
}

.toregist {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  text-align: center;
  margin-top: 21px;

  span {
    color: #1990ff;
    cursor: pointer;
    font-size: 14px;
  }
}

.codeNumber {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #838f99;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

/deep/.inputtext {
  font-size: 14px;
  color: #111;
}
</style>
